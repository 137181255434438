import React from "react"
import PropTypes from "prop-types"

export const wrapRootElement = ({ element }) => {
  return <> {element} </>
}

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}

export default wrapRootElement
